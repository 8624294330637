import React from 'react'
import { Box } from 'rebass'

export default function Phone({ size = 24, ...props }) {
  return (
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 100 100"
      stroke="currentColor"
      fill="currentColor"
      {...props}
    >
      <title>Phone</title>
      <path d="M81.59,60.36277c-4.40032-3.75838-9.24-3.89312-13.27418-.3754-2.72981,2.36564-4.09765,3.54456-6.84309,5.89312a.922.922,0,0,1-1.21162-.0908c-4.3105-3.7203-22.32768-21.73748-26.0514-26.05188a.92309.92309,0,0,1-.09079-1.21162c2.349-2.744,3.52747-4.11328,5.88824-6.83773,3.52259-4.04005,3.38786-8.8802-.37-13.27954a117.17353,117.17353,0,0,0-8.39741-8.98906C25.46274,3.75031,19.347,3.52869,14.01723,8.79257A37.91733,37.91733,0,0,0,6.97106,19.13775C-1.9809,38.20058,22.0202,62.20412,29.908,70.09189,36.58606,76.77,54.81706,95,71.82081,95a21.02989,21.02989,0,0,0,9.04178-1.97023A37.95489,37.95489,0,0,0,91.2068,85.98311c5.26437-5.32978,5.04176-11.44648-.6268-17.22245A117.33091,117.33091,0,0,0,81.59,60.36277Zm6.061,22.10751a33.20222,33.20222,0,0,1-8.91291,6.0342C68.1957,93.45594,52.95617,86.07,33.44228,66.55758,13.93034,47.04271,6.54636,31.80318,11.49537,21.26224a33.19664,33.19664,0,0,1,6.0347-8.91339,6.655,6.655,0,0,1,4.57214-2.3349c1.57482,0,3.42009.79864,5.63733,2.97439a111.69712,111.69712,0,0,1,8.0967,8.66785c2.75715,3.22726,1.66366,5.301.39736,6.75327C33.86015,31.149,32.67781,32.52225,30.32,35.277a5.89753,5.89753,0,0,0,.10544,7.72667C34.33855,47.53722,52.46606,65.66472,56.99525,69.574a5.89431,5.89431,0,0,0,7.72764.10446c2.75423-2.35637,4.12792-3.53919,6.87238-5.91851,1.44595-1.259,3.51772-2.35539,6.74742.40323a111.786,111.786,0,0,1,8.66882,8.09816l.001.00049C91.72133,77.06,89.9776,80.11488,87.651,82.47028Z" />
    </Box>
  )
}
