import React from 'react'
import { Heading, Link, Box } from 'rebass'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Container from '../components/container'
import Headline from '../components/headline'
import Email from '../components/icons/email'
import Phone from '../components/icons/phone'
import Hr from '../components/hr'
import GetAQuote from '../components/get-a-quote/questionnaire'

const ContactLink = props => (
  <Link
    {...props}
    sx={{
      color: 'white',
      textDecoration: 'none',
      borderBottom: 'solid 2px transparent',
      ':hover': { borderBottom: 'solid 2px', borderBottomColor: 'gold' },
    }}
  />
)

export default function Contact() {
  const data = useStaticQuery(graphql`
    query {
      headerBackgroundImage: file(relativePath: { eq: "escalators.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Contact me" />
      <Headline fluidBackgroundImage={data.headerBackgroundImage.childImageSharp.fluid}>
        <Heading variant="headline" as="h1">
          Contact information
        </Heading>
        <Hr color="gold" />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '80px 1fr'],
            gridGap: '1rem',
            placeItems: 'center',
          }}
        >
          <Email color="gold" size={38} />
          <Heading as="h2" fontWeight="lighter" fontSize={[3, null, 5]} sx={{ justifySelf: ['center', 'flex-start'] }}>
            <ContactLink href="mailto:idunnsofieriise@gmail.com">idunnsofieriise@gmail.com</ContactLink>
          </Heading>
          <Phone color="gold" size={38} />
          <Heading
            as="h2"
            fontWeight="lighter"
            fontSize={[3, null, 5]}
            sx={{ justifySelf: ['center', 'flex-start'] }}
            display="flex"
            flexDirection={['column', 'row']}
            textAlign={['center', 'left']}
          >
            <ContactLink href="tel:+447539437644">+447539437644 (UK)</ContactLink>
            <Box as="span" mx="3" display={['none', 'inline']} sx={{ borderRight: 'solid 2px white' }} />
            <ContactLink href="tel:+4792303382">+4792303382 (NO)</ContactLink>
          </Heading>
        </Box>
      </Headline>
      <Container pb={0} sx={{ position: 'relative' }}>
        <Box id="get-a-quote" sx={{ display: 'flex', justifyContent: 'center' }}>
          <GetAQuote />
        </Box>
      </Container>
    </Layout>
  )
}
